.wrapper {
  width: 24px;
  height: 24px;
  opacity: 0.8;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
