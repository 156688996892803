.wrapper {
  margin-right: 10px;
  border-radius: 50%;
  background: #e9ccff;
  padding: 5px 7px;
  display: flex;
  align-items: center;
  justify-content: center;

  li {
    color: #9001ff;
  }
}
