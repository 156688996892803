@import "root";
@import "variables";

@import "mixins/breakpoints";
@import "mixins/grid";
@import "mixins/spacers";
@import "mixins/forsom";

@import "components/normalize";
@import "components/grid";
@import "components/spacers";
@import "components/carousel";
@import "fonts/fonts.css";

// forsom
@import "src/components/Button/_variables.scss";
@import "src/components/Input/_variables.scss";
@import "src/components/Slider/_variables.scss";
@import "src/components/Select/components/List/_variables.scss";
@import "src/components/Carousel/_variables.scss";

.shadowLevel1 {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.08);
}

.shadowLevel2 {
  box-shadow: 0 16px 32px rgba(0, 0, 0, 0.15), 0 8px 16px rgba(0, 0, 0, 0.04);
}

.visually-hidden {
  position: absolute;

  width: 1px;
  height: 1px;
  overflow: hidden;

  white-space: nowrap;

  clip: rect(0 0 0 0);
  clip-path: inset(50%);
}

.text-center {
  text-align: center;
}

.pre-wrap {
  white-space: pre-wrap;
}

.intercom_launcher {
  position: fixed;
  bottom: 15px;
  right: 15px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  background: linear-gradient(
    285.27deg,
    #f0b60a 5.55%,
    #f09d9d 50.3%,
    #89a3ff 91.15%
  );
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  color: var(--font-color) !important;
  background: var(--input-background);
  outline: none;
  border: none;
  -webkit-text-fill-color: var(--font-color);
  -webkit-box-shadow: 0 0 0 1px var(--input-border-shadow-color) inset;
  transition: background-color 0s 600000s,
    color 0s 600000s !important;
}

input[data-com-onepassword-filled="light"],
input[data-com-onepassword-filled="dark"],
select[data-com-onepassword-filled="dark"],
textarea[data-com-onepassword-filled="dark"] {
  background-color: transparent !important;
}

@font-face {
  font-display: swap;
  font-weight: 400;
  font-family: Mont;
  src: url('/fonts/Mont-Regular.woff2') format('woff2');
}

@font-face {
  font-display: swap;
  font-weight: 500;
  font-family: Mont;
  src: url('/fonts/Mont-SemiBold.woff2') format('woff2');
}

* {
  box-sizing: border-box;
  font-family: Mont, serif;
  line-height: 1.5;
}

.root {
  width: 100%;
  height: 100%;
}

html,
body {
  background: #f2f5f8 !important;
}

html,
body,
div,
span,
input,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul,
li {
  margin: 0;
  padding: 0;
}

ol,
ul {
  list-style: none;
}

a {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
