:root {
  --font-color: #000000;
  --input-background: #fff;
  --input-background-disable: #f3f3f3;
  --input-color-disable: black;
  --input-border: #00adff;
  --input-placeholder-color: #999;
  --input-placeholder-disable-color: #b5b5b5;
  --input-border-shadow-color: #dcdcdc;
  --input-border-shadow-disable-color: #bfbfbf;
}
