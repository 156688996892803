@import "styles/mixins/forsom";

.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 8;
  width: 100vw;
  overflow: auto;
  transition: background 300ms ease-in-out;
}

.overlayShow {
  display: block;
  background: rgb(0 0 0 / 50%);
  animation: show-opacity 300ms;
}

.overlayHidden {
  display: none;
}

.wrapper {
  width: 100%;
  margin: 0;
  padding: 80px 16px 16px;
  background: white;
}

.wrapperOpen {
  top: 0;
}

.wrapperClose {
  top: -200%;
}

.title {
  color: #7e7e7e;
  font-size: 20px;
}

.hr {
  display: block;
  height: 1px;
  margin: 1em 0 24px;
  padding: 0;
  border: 0;
  border-top: 1px solid #d9d9d9;
}

.content {
  display: flex;
  flex-wrap: wrap;
  gap: 0 20px;
  justify-content: space-between;
}

.ul {
  min-width: 210px;
}

.li {
  padding: 10px;
  color: #8f00ff;
  border-radius: 6px;
  cursor: pointer;

  &:hover {
    background: #f5f5f5;
  }
}

@media (width <= 680px) {
  .wrapper {
    padding: 120px 16px 16px;
  }

  .li {
    line-height: 1;
  }
}

@media (width <= 480px) {
  .ul {
    width: calc(50% - 10px);
    min-width: auto;
  }

  .li {
    font-size: 14px;
  }
}
