:root {
  --input-default-background-color: transparent;
  --input-disabled-background-color: #f3f3f3;

  --input-error-background-color: #ffe3e3;

  --input-default-placeholder-color: #7c8a9e;
  --input-disabled-placeholder-color: #d6d6d6;

  --input-value-color: #2c2e2b;
  --input-error-text-color: #ff0000;
  --input-height: 54px;
  --input-font-size: 16px;
  --input-placeholder-font-size: 16px;
  --input-border-radius: 10px;

  --input-default-box-shadow: none;
  --input-disabled-box-shadow: none;
  --input-focus-box-shadow: none;
  --input-hover-box-shadow: none;

  --input-default-border: 1px solid #999999;
  --input-disabled-border: 1px solid #999999;
  --input-focus-border: 1px solid #000;
  --input-hover-border: 1px solid #000;

  --button-hover-color: white;
  --button-active-color: white;
  --button-default-background-color: linear-gradient(
    76.72deg,
    #002bff 8.35%,
    #7800c1 116.12%
  );
  --button-hover-background-color: linear-gradient(
    76.72deg,
    #002bff 8.35%,
    #7800c1 116.12%
  );
  --button-active-background-color: linear-gradient(
    76.72deg,
    #002bff 8.35%,
    #7800c1 116.12%
  );
  --button-border-color: transparent;
  --button-active-border-color: transparent;
  --button-color: white;
  --button-disabled-color: white;
  --button-disabled-background-color: linear-gradient(
    76.72deg,
    #002bff 8.35%,
    #7800c1 116.12%
  );
  --button-focus-outline-color: transparent;
  --button-min-width: 224px;
  --button-border-radius: 10px;
  --button-border-width: 0px;
  --button-font-weight: 400;

  --button-height-medium: 48px;
  --button-font-size-medium: 20px;
  --button-min-width-medium: 224px;

  --button-min-width-large: 350px;
  --button-height-large: 64px;
  --button-font-size-large: 20px;

  @media (max-width: 710px) {
    --button-min-width-large: 224px;
    --button-height-large: 48px;
  }

  --button-default-opacity: 1;
  --button-hover-opacity: 0.7;
  --button-active-opacity: 0.5;

  --typography-h1-font-size: 84px;
  --typography-h1-line-height: 97px;
  --typography-h1-font-weight: 500;
  --typography-h1-letter-spacing: 0;
  --typography-h1-color: #000;

  @media (max-width: 710px) {
    --typography-h1-font-size: 40px;
    --typography-h1-line-height: 50px;
  }

  --typography-h2-font-size: 40px;
  --typography-h2-line-height: 67px;
  --typography-h2-font-weight: 500;
  --typography-h2-letter-spacing: 0;
  --typography-h2-color: #000;

  @media (max-width: 710px) {
    --typography-h2-font-size: 24px;
    --typography-h2-line-height: 32px;
  }

  --typography-body-m-font-size: 24px;
  --typography-body-m-line-height: 40px;
  --typography-body-m-letter-spacing: 0;
  --typography-body-m-color: #000;

  @media (max-width: 710px) {
    --typography-body-m-font-size: 16px;
    --typography-body-m-line-height: 24px;
  }

  --typography-caption1-font-size: 18px;
  --typography-caption1-line-height: 30px;
  --typography-caption1-font-weight: 400;
  --typography-caption1-letter-spacing: 0;

  @media (max-width: 710px) {
    --typography-caption1-font-size: 12px;
    --typography-caption1-line-height: 20px;
  }

  --typography-subtitle1-font-size: 24px;
  --typography-subtitle1-line-height: 24px;
  --typography-subtitle1-font-weight: 500;
  --typography-subtitle1-letter-spacing: 0;
}
