@import "styles/mixins/forsom";

.header {
  position: relative;
  z-index: 10;
  width: 100%;
  background: #8f01ff;
  box-shadow: 0 6px 6px 0 #0000001f;
}

.wrapper {
  @include container_max-width_1440;

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px;
  cursor: pointer;
}

.logoWrapper {
  display: flex;
  align-items: center;
  width: 150px;
  margin-left: 20px;
}

.forsomLogo {
  min-width: 52px;
  height: 52px;
  margin: 0 16px 0 0;
}

.input {
  margin-right: 6px;

  input {
    padding-top: 13px;
    padding-bottom: 13px;
  }
}

.button {
  height: 50px;
  margin-right: 10px;
  padding: 0 48px;
  font-size: 16px;
}

.burgerButton {
  height: 50px;
  margin-right: 6px;
  padding: 0 16px;
}

.burgerIcon {
  width: 26px;
  height: 16px;
  color: #8f00ff;
}

@media (width <= 680px) {
  .wrapper {
    flex-wrap: wrap;
  }

  .burgerButton {
    width: 42px;
    height: 42px;
    margin-right: 0;
    padding: 0 11px;
  }

  .button {
    width: 20%;
    height: 40px;
    margin-right: 0;
    padding: 0;
  }

  .input {
    width: calc(80% - 70px);

    input {
      padding-top: 8px;
      padding-bottom: 8px;
    }
  }
}
