:root {
  --rs-slider-progress: #8f00ff;
  --rs-slider-thumb-border: #8f00ff;
  --rs-slider-thumb-bg: #fff;
}

.rs-slider {
  position: relative;
  border-radius: 4px;
  box-shadow: 0 0 0 1px #bfbfbf;
}

.rs-slider .rs-tooltip {
  display: none;
}

.rs-slider .rs-tooltip.rs-tooltip-placement-top .rs-tooltip::after {
  right: 0;
  left: 0;
  margin: auto;
}

.rs-slider-disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.rs-slider-disabled .rs-slider-bar,
.rs-slider-disabled .rs-slider-handle::before {
  pointer-events: none;
}

.rs-slider-with-mark:not(.rs-slider-vertical) {
  margin-bottom: 29px;
}

.rs-slider-bar {
  height: 6px;
  border-radius: 3px;
  background-color: #f2f2f5;
  transition: background-color ease-in-out 0.3s;
  cursor: pointer;
}

.rs-slider:hover .rs-slider-bar {
  background-color: #e5e5ea;
}

.rs-slider-vertical .rs-slider-bar {
  height: 100%;
  width: 6px;
}

.rs-slider-handle {
  position: absolute;
  top: -50%;
  outline: none;
}

.rs-slider-handle::before {
  content: '';
  position: absolute;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: 2px solid var(--rs-slider-thumb-border);
  background-color: #fff;
  background-color: var(--rs-slider-thumb-bg);
  margin-left: -6px;
  cursor: pointer;
  /* stylelint-disable */

  -webkit-transition: background-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;

  transition: background-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;

  transition: box-shadow 0.15s ease-in-out, background-color 0.15s ease-in-out, transform 0.15s ease-in-out;

  transition: box-shadow 0.15s ease-in-out, background-color 0.15s ease-in-out, transform 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
  /* stylelint-enable */
}

.rs-slider-handle:hover::before,

.rs-slider-handle:focus::before {
  -webkit-box-shadow: var(--rs-slider-thumb-hover-shadow);
  box-shadow: var(--rs-slider-thumb-hover-shadow);
}

.rs-slider-handle:active::before,

.rs-slider-handle.active::before {

  -webkit-transform: scale(1.2);

  transform: scale(1.2);
}

.rs-slider-vertical .rs-slider-handle {
  top: unset;
}

.rs-slider-vertical .rs-slider-handle::before {
  left: 3px;
  margin-top: -6px;
}

.rs-slider-handle:hover .rs-tooltip,

.rs-slider-handle.active .rs-tooltip {
  display: block;
  opacity: 1;
  top: -30px;
}

.rs-slider-vertical .rs-slider-handle:hover .rs-tooltip,

.rs-slider-vertical .rs-slider-handle.active .rs-tooltip {
  top: -33px;
  margin-left: 3px;
}

.rs-slider-mark {
  position: absolute;
  top: 15px;
  left: -2px;
  white-space: nowrap;
}

.rs-slider-mark-content {
  margin-left: -50%;
}

.rs-slider-mark-last {
  left: auto;
  right: -2px;
}

.rs-slider-mark-last .rs-slider-mark-content {
  margin-left: 50%;
}

.rs-slider-graduator {
  width: 100%;
}

.rs-slider-graduator ol,
.rs-slider-graduator li {
  list-style: none;
}

.rs-slider-graduator > ol {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-left: 0;
  width: 100%;
}

.rs-slider-graduator > ol > li {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 1%;
  flex: 1 1 1%;
  position: relative;
}

.rs-slider-graduator > ol > li:last-child::after,

.rs-slider-graduator > ol > li::before {
  content: '';
  display: block;
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #fff;
  background-color: var(--rs-slider-thumb-bg);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 2px solid #f2f2f5;
  border: 2px solid var(--rs-slider-bar);
  margin-left: -4px;
  top: -1px;
}

.rs-slider-vertical .rs-slider-graduator > ol > li:last-child::after,

.rs-slider-vertical .rs-slider-graduator > ol > li::before {
  top: unset;
  bottom: -4px;
  margin-left: -1px;
}

.rs-slider-graduator > ol > li:last-child::after {
  right: -4px;
}

.rs-slider-vertical .rs-slider-graduator > ol > li:last-child::after {
  left: 0;
  bottom: unset;
  top: -4px;
}

.rs-slider-graduator > ol > li.rs-slider-pass::before {
  border-color: #3498ff;
  border-color: var(--rs-slider-progress);
}

.rs-slider-graduator > ol > li.rs-slider-active::before {
  visibility: hidden;
}

.rs-slider-vertical .rs-slider-graduator {
  display: block;
  height: 100%;
}

.rs-slider-vertical .rs-slider-graduator > ol {
  width: 6px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse;
  height: 100%;
  padding: 0;
}

.rs-slider-vertical .rs-slider-graduator > ol > li {
  display: block;
  padding: 0;
}

.rs-slider-progress-bar {
  position: absolute;
  height: 6px;
  border-radius: 3px 0 0 3px;
  background-color: #3498ff;
  background-color: var(--rs-slider-progress);
}

.rs-slider-vertical .rs-slider-progress-bar {
  width: 6px;
  border-radius: 0 0 3px 3px;
}

.rs-slider-vertical {
  height: 100%;
}

.rs-slider-vertical .rs-slider-mark {
  top: unset;
  bottom: -8px;
  left: 15px;
}

.rs-slider-vertical .rs-slider-mark-content {
  margin-left: auto;
}

.rs-slider-vertical .rs-slider-mark-last {
  bottom: unset;
  top: -8px;
}
