.button {
  height: 36px;
  padding: 0 16px;
  font-weight: 400;
  font-size: 14px;
  text-transform: none;
  border-color: unset;
  border-style: unset;
  border-radius: 6px;
  cursor: pointer;
  transition:
    transform 100ms ease-in-out,
    box-shadow 100ms ease-in-out,
    background-color 100ms ease-in-out;
  user-select: none;

  &:disabled {
    background: #d0d0d0;
    cursor: auto;
  }

  &:not(:disabled):focus {
    outline: none;
  }

  &:not(:disabled):active {
    border-style: unset;
  }

  &:not(:disabled):hover {
    outline: none;
  }
}

.content {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contentLoading {
  color: transparent;
}

.positiveActive {
  color: var(--button-positive-color);
  background: var(--button-positive-background);

  &:not(:disabled):hover {
    background: var(--button-positive-background-hover);
  }
}

.negativeActive {
  color: white;
  background: var(--button-negative-background);

  &:not(:disabled):focus {
    outline: none;
  }
}

.warningActive {
  color: white;
  background: var(--button-warning-background);

  &:not(:disabled):focus {
    outline: none;
  }
}

.invisibleBlue {
  color: var(--button-invisible-blue-color);
  background: transparent;

  &:not(:disabled):focus {
    outline: none;
  }
}

.whiteActive {
  color: black;
  background: white;

  &:not(:disabled):hover {
    background: #f1dbffff;
  }
}

.lightActive {
  color: var(--button-light-color);
  background: var(--button-light-background);

  &:not(:disabled):hover {
    background: var(--button-light-background-hover);
  }
}

.loader {
  position: absolute;
  display: flex;
  align-items: center;
  height: 20px;
}
