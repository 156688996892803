:root {
  --rs-carousel-bg: #717273;
  --rs-carousel-indicator-hover: #fff;
  --rs-carousel-indicator-active: white;
}

.rs-carousel {
  position: relative;
  height: 400px;
  overflow: hidden;
  background-color: #717273;
  background-color: var(--rs-carousel-bg);
}

.rs-carousel-content {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.rs-carousel-slider {
  position: relative;
  left: 0;
  height: 100%;
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition:
    transform 0.3s ease,
    -webkit-transform 0.3s ease;
  will-change: transform;
}

.rs-carousel-slider-item {
  float: left;
  width: 100%;
  height: 100%;
  background-color: #717273;
  background-color: var(--rs-carousel-bg);
}

.rs-carousel-slider-after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #717273;
  background-color: var(--rs-carousel-bg);
  animation: move-left-half 0.3s ease forwards;
}

.rs-carousel-slider-after-vertical {
  animation: move-left-half-vertical 0.3s ease forwards;
}

.rs-carousel-toolbar {
  position: absolute;
  display: -webkit-box;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
}

.rs-carousel-toolbar > ul {
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  list-style: none;
}

.rs-carousel-label-wrapper {
  margin: 3px;
}

.rs-carousel-label-wrapper input {
  position: absolute;
  width: 0;
  height: 0;
  opacity: 0;
}

.rs-carousel-label-wrapper input:checked ~ label {
  background-color: #3498ff;
  background-color: var(--rs-carousel-indicator-active);
}

.rs-carousel-label {
  position: relative;
  display: block;
  background-color: var(--rs-carousel-indicator);
  cursor: pointer;
  transition: background 0.3s linear;
  transition-property: background, width, height;
}

.rs-carousel-label::after {
  position: absolute;
  inset: -3px;
  content: '';
}

.rs-carousel-label:hover {
  background-color: #fff;
  background-color: var(--rs-carousel-indicator-hover);
}

.rs-carousel-shape-dot .rs-carousel-label {
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.rs-carousel-shape-bar .rs-carousel-label {
  border-radius: 2px;
}

.rs-carousel-placement-top.rs-carousel-shape-bar .rs-carousel-label,
.rs-carousel-placement-bottom.rs-carousel-shape-bar .rs-carousel-label {
  width: 18px;
  height: 4px;
}

.rs-carousel-placement-top.rs-carousel-shape-bar .rs-carousel-label-wrapper input:checked ~ label,
.rs-carousel-placement-bottom.rs-carousel-shape-bar
.rs-carousel-label-wrapper
input:checked
~ label {
  width: 28px;
}

.rs-carousel-placement-left.rs-carousel-shape-bar .rs-carousel-label,
.rs-carousel-placement-right.rs-carousel-shape-bar .rs-carousel-label {
  width: 4px;
  height: 18px;
}

.rs-carousel-placement-left.rs-carousel-shape-bar .rs-carousel-label-wrapper input:checked ~ label,
.rs-carousel-placement-right.rs-carousel-shape-bar
.rs-carousel-label-wrapper
input:checked
~ label {
  height: 28px;
}

.rs-carousel-placement-top .rs-carousel-toolbar,
.rs-carousel-placement-bottom .rs-carousel-toolbar {
  left: 0;
  width: 100%;
}

.rs-carousel-placement-top .rs-carousel-toolbar > ul,
.rs-carousel-placement-bottom .rs-carousel-toolbar > ul {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.rs-carousel-placement-top .rs-carousel-toolbar {
  top: 17px;
}

.rs-carousel-placement-bottom .rs-carousel-toolbar {
  bottom: 17px;
}

.rs-carousel-placement-left .rs-carousel-toolbar,
.rs-carousel-placement-right .rs-carousel-toolbar {
  top: 0;
  width: 1.2vw;
  height: 100%;
}

.rs-carousel-placement-left .rs-carousel-toolbar > ul,
.rs-carousel-placement-right .rs-carousel-toolbar > ul {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
}

.rs-carousel-placement-left .rs-carousel-toolbar {
  left: 17px;
}

.rs-carousel-placement-right .rs-carousel-toolbar {
  right: 17px;
}

@keyframes move-left-half {
  0% {
    transform: none;
  }

  100% {
    transform: translateX(-50%);
  }
}

@keyframes move-left-half {
  0% {
    transform: none;
  }

  100% {
    transform: translateX(-50%);
  }
}

@keyframes move-left-half-vertical {
  0% {
    transform: none;
  }

  100% {
    transform: translateY(-50%);
  }
}

@keyframes move-left-half-vertical {
  0% {
    transform: none;
  }

  100% {
    transform: translateY(-50%);
  }
}
