.wrapper {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.header {
  flex-grow: 0;
}

.main {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;
}

.footer {
  flex-grow: 0;
}
