.wrapper {
  display: flex;
  justify-content: space-between;
  padding: 16px 20px;
  background-color: #d9d9d9;
}

.links {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  align-items: center;
  width: 75%;

  span {
    color: #585858;
    font-size: 12px;
    text-decoration: underline;
    text-decoration-color: #585858;
    cursor: pointer;
    padding-top: 3px;
  }
}

.icons {
  display: flex;
  flex-wrap: wrap;
  gap: 10px 20px;
  align-items: center;
  justify-content: flex-end;
  width: 25%;
}

.svg {
  width: 30px;
  height: 30px;
}

.modal {
  max-width: 500px;
}

.link {
  color: #3c4fff;
  text-decoration: underline;
}

@media (width <= 680px) {
  .productPage {
    padding-bottom: 80px;
  }
}
