.wrapper {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 100;
  width: calc(100% - 40px);
  max-height: calc(100vh - 20px);
  padding: 20px;
  overflow: auto;
  background: #fbfbfb;
  border-radius: 8px;
  box-shadow: 6px 6px 12px 0 grey;
  transform: translate(-50%, -50%);
  animation: 500ms show;
}

.close {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  margin: 5px;
  color: #929292;
  font-size: 20px;
  text-shadow: 0 0 0 transparent;
  cursor: pointer;
  transition:
    text-shadow 150ms ease-in-out,
    font-size 150ms ease-in-out;
  user-select: none;

  &:hover {
    font-size: 21px;
    text-shadow: 3px 1px 3px #ababab;
  }

  &:active,
  &:focus {
    font-size: 20px;
    text-shadow: 0 0 0 transparent;
  }
}

@keyframes show {
  from {
    top: calc(50% + 12px);
    box-shadow: 0 0 0 0 transparent;
    opacity: 0;
  }

  to {
    top: 50%;
    box-shadow: 6px 6px 12px 0 grey;
    opacity: 1;
  }
}
