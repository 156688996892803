@font-face {
  font-display: swap;
  font-weight: 400;
  font-family: Mont;
  src: url('/fonts/Mont-Regular.woff2') format('woff2');
}

@font-face {
  font-display: swap;
  font-weight: 500;
  font-family: Mont;
  src: url('/fonts/Mont-SemiBold.woff2') format('woff2');
}

.wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: calc(100% - 20px);
  max-width: 1100px;
  font-family: Mont, serif;
  text-align: center;
  background: white;
  border-radius: 8px;
  transform: translate(-50%, -50%);
}

.container404,
.container500 {
  width: 100%;
  max-width: 514px;
  margin: 72px auto 88px;
  padding: 0 10px;
}

.container500 {
  max-width: 742px;
}

.title {
  margin-bottom: 32px;
  font-weight: 500;
  font-size: 40px;
}

.text {
  margin-bottom: 64px;
  font-weight: 400;
  font-size: 24px;
}

.img {
  width: 100%;
  max-width: 514px;
}
