@mixin centerAbsolut {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin container_max-width_1440 {
  max-width: 1440px;
  margin: 0 auto;
}

@mixin centerAbsolut {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@keyframes show-opacity {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
