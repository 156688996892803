.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  user-select: none;

  .iconColor {
    opacity: 1;
    transition: 50ms opacity ease-in-out;
  }
}

.wrapperDisabled {
  .iconColor {
    opacity: 0.3;
  }
}

.required {
  padding: 5px;
  color: #eb236b;
}

.label,
.helperText {
  display: flex;
  align-items: center;
  max-width: 300px;
  height: 16px;
  padding: 0 7px;
  color: var(--font-color);
  font-size: 12px;
  user-select: none;

  span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.labelDisabled {
  cursor: auto;
}

.helperText {
  position: absolute;
  bottom: -2px;
  left: 8px;
  z-index: 2;
  color: #eb236b;
  border-radius: 0 0 5px 5px;
  animation: helper-text 200ms ease-in-out;
}

.input {
  box-sizing: border-box;
  width: 100%;
  padding: 6px 12px;
  overflow: hidden;
  color: var(--font-color);
  font-size: 16px;
  text-overflow: ellipsis;
  background-color: var(--input-background) !important;
  border: none;
  border-radius: 6px;
  box-shadow: 0 0 0 1px var(--input-border-shadow-color) inset;
  transition: 50ms box-shadow ease-in-out;

  &::placeholder {
    color: var(--input-placeholder-color);
    font-size: 14px;
  }

  &:disabled::placeholder {
    color: var(--input-placeholder-disable-color);
  }

  &:disabled {
    background: var(--input-background-disable);
    box-shadow: 0 0 0 1px var(--input-border-shadow-disable-color) inset;
    cursor: auto;
    opacity: 0.3;
  }

  &:not(:disabled):hover {
    box-shadow: 0 0 0 2px var(--input-border-shadow-color) inset;
  }

  &:not(:disabled):focus-visible,
  &:not(:disabled):focus {
    outline: none;
  }

  &.error {
    box-shadow: 0 0 0 1px #eb236b inset;
  }

  &:hover.error {
    box-shadow: 0 0 0 1px #eb236b inset;
  }

  &:focus-visible.error,
  &:focus.error {
    outline: none;
    box-shadow: 0 0 0 2px #eb236b inset;
  }
}

.buttonsContainer {
  position: relative;
  margin: 0 0 12px;
}

.buttonsContainerWithoutHelperText {
  margin: 0;
}

.buttons {
  position: absolute;
  top: 50%;
  right: 12px;
  display: flex;
  align-items: center;
  transform: translateY(-50%);
}

.withIcon {
  padding-right: 36px;
}

.withDoubleIcons {
  padding-right: 64px;
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  color: var(--icon-color);
}

.clearButton {
  width: 24px;
  height: 24px;
  color: var(--icon-color);
  cursor: pointer;
  transition: 50ms opacity ease-in-out;
  user-select: none;
}

.inactiveClearButton {
  cursor: auto;
  opacity: 0.3;
}

.loader {
  position: absolute;
  top: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  transform: translateY(-50%);
}

.cursorPointer {
  cursor: pointer;
  user-select: auto;
  pointer-events: auto;
}

@keyframes helper-text {
  from {
    bottom: 0;
  }

  to {
    bottom: -2px;
  }
}
